import * as React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import style from "./ImageCarousel.module.css";

export interface ImageCarouselProps {
  startingIndex?: number;
  fullScreen?: boolean;
  photos: {
    sizes: string;
    srcSet: string;
    height: number;
    width: number;
  }[],
  onClose?(): void;
}

function CarouselImage(props: {
  fullScreen?: boolean;
  photo: {
    sizes: string;
    srcSet: string;
    height: number;
    width: number;
  }
}) {
  return <div className={style.carouselImageContainer}><img className={style.carouselImage} srcSet={props.photo.srcSet} sizes={props.photo.sizes} /></div>;
}

enum TransitionState {
  NEUTRAL,
  LAST,
  NEXT,
  RESTORE
}

export function ImageCarousel(props: ImageCarouselProps) {
  const [visible, setVisible] = React.useState(!props.fullScreen);
  const [state, setState] = React.useState<{ index: number; transition: TransitionState; }>({ index: props.startingIndex || 0, transition: TransitionState.NEUTRAL });

  const index = state.index;
  const transition = state.transition;


  const lastIndex = index > 0 ? index - 1 : props.photos.length - 1;
  const nextIndex = index === props.photos.length - 1 ? 0 : index + 1;
  const divRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (!props.fullScreen) {
      return;
    }

    const html = document.getElementsByTagName("html")[0];
    html.style.overflow = "hidden";
    document.body.style.overflow = "hidden";
    document.body.style.position = "relative";
    html.style.position = "relative";

    const listener = (myEvent) => {
      myEvent.preventDefault();
    };

    divRef.current.addEventListener("touchmove", listener);

    setVisible(true);
    return () => {
      html.style.overflow = null;
      document.body.style.overflow = null;
      document.body.style.position = null;
      html.style.position = null;
      divRef.current.removeEventListener("touchmove", listener);
    };
  }, []);

  React.useEffect(() => {
    if (transition !== TransitionState.NEUTRAL) {
      setTimeout(() => {
        if (transition === TransitionState.NEXT) {
          setState({ index: nextIndex, transition: TransitionState.NEUTRAL });
        } else if (transition === TransitionState.LAST) {
          setState({ index: lastIndex, transition: TransitionState.NEUTRAL });
        }
      }, 550);
    }
  }, [transition]);

  const [initial, setInitial] = React.useState<number | null>(null);
  const [diff, setDiff] = React.useState<number | null>(null);

  let translation: string;
  if (diff !== null) {
    translation = `calc(${-diff}px - 100vw)`
  } else {
    translation = `${((transition === TransitionState.NEUTRAL || transition === TransitionState.RESTORE) && -100) || (transition === TransitionState.NEXT && -200) || (0)}vw`;
  }

  return (
    <div
      ref={divRef}
      style={props.fullScreen ? {
        position: "fixed",
        visibility: visible ? undefined : "hidden",
        opacity: visible ? 1 : 0,
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        zIndex: 10
      } : {}}
      className={style.carouselViewport}
      onTouchStart={(event) => {
        const touch = event.changedTouches[0];
        setInitial(touch.clientX);
      }}

      onTouchMove={(event) => {
        const touch = event.changedTouches[0];
        setDiff(initial - touch.clientX);
      }}
      onTouchEnd={(event) => {
        if (diff === null) {
          return;
        }

        setInitial(null);
        setDiff(null);
        if (Math.abs(diff) > (window.innerWidth * .35)) {
          if (diff > 0) {
            setState({ index, transition: TransitionState.NEXT });
          } else {
            setState({ index, transition: TransitionState.LAST });
          }
        } else {
          setState({ index, transition: TransitionState.RESTORE });
        }
      }}
    >
      <div className={style.allImagesContainer}
        style={{
          width: "300vw",
          transform: `translate3d(${translation}, 0px, 0px)`,
          transition: transition !== TransitionState.NEUTRAL ? "transform 400ms ease" : undefined
        }}>
        {
          [
            <CarouselImage key={lastIndex} photo={props.photos[lastIndex]} />,
            <CarouselImage key={index} photo={props.photos[index]} />,
            <CarouselImage key={nextIndex} photo={props.photos[nextIndex]} />
          ]
        }
      </div>
      <button className={`${style.buttons} ${style.rightButton}`} onClick={() => { setState({ index, transition: TransitionState.NEXT }) }}>
        <FontAwesomeIcon icon={["fas", "chevron-right"]} />
      </button>
      <button className={`${style.buttons} ${style.leftButton}`} onClick={() => { setState({ index, transition: TransitionState.LAST }) }}>
        <FontAwesomeIcon icon={["fas", "chevron-left"]} />
      </button>
      {
        props.onClose && <button className={`${style.buttons} ${style.closeButton}`} onClick={props.onClose}>
          <FontAwesomeIcon icon={["fas", "times"]} />
        </button>
      }
    </div>
  )
}