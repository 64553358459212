import React, { useState, useCallback } from "react"
import ParallaxHooklessImage from "../../components/parallax-hookless-image/ParallaxHooklessImage"

import { graphql } from "gatsby"
import TextBlock from "../../components/text-block/TextBlock"
import Gallery from "react-photo-gallery"

// Unfortunately the typings for this is outdated
import Carousel, { Modal, ModalGateway } from "react-images";
import { Head } from "../../components/head/Head"
import { ImageCarousel } from "../../components/image-carousel/ImageCarousel"

export default ({ data }) => {
  const photos: {
    sizes: string;
    src: string;
    srcSet: string;
    height: number;
    width: number;
  }[] = React.useMemo(() => data.images.nodes.map((node) => {
    return {
      sizes: node.childImageSharp.sizes.sizes,
      src: node.childImageSharp.sizes.src,
      srcSet: node.childImageSharp.sizes.srcSet,
      height: node.childImageSharp.original.height,
      width: node.childImageSharp.original.width,
    }
  }), [data.images.nodes]);
  const firstSet = React.useMemo(() => photos.slice(0, 30), [photos]);
  const secondSet = React.useMemo(() => photos.slice(31), [photos]);

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const firstOpenLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setTimeout(() => {
      setViewerIsOpen(true);
    }, 100);
  }, []);

  const secondOpenLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index + 31);
    setTimeout(() => {
      setViewerIsOpen(true);
    }, 100);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <React.Fragment>
      <Head title="Alex & Alexis - Gallery" imageSrc={data.portrait.childImageSharp.fluid.src} />

      <ParallaxHooklessImage
        src={data.portrait.childImageSharp.fluid.src}
        height={data.portrait.childImageSharp.fluid.presentationHeight}
        width={data.portrait.childImageSharp.fluid.presentationWidth}
      >
        <h1>Gallery</h1>
      </ParallaxHooklessImage>
      <TextBlock>
        <Gallery photos={firstSet} onClick={firstOpenLightbox}></Gallery>
      </TextBlock>
      <TextBlock height="20vh"><h2>loved you yesterday, love you still<br /> always have, always will</h2></TextBlock>
      <TextBlock>
        <Gallery photos={secondSet} onClick={secondOpenLightbox}></Gallery>
      </TextBlock>
      {viewerIsOpen ? (
        <ImageCarousel photos={photos} fullScreen={true} onClose={closeLightbox} startingIndex={currentImage} />
      ) : null}
    </React.Fragment>
  )
}

export const query = graphql`
{
  portrait: file(relativePath: {eq: "gallery.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 3440) {
        src
        presentationHeight
        presentationWidth
      }
    }
  }

  images: allFile(filter: {relativeDirectory: {eq: "gallery"}}, sort: {fields: name, order: ASC}) {
    nodes {
      childImageSharp {
        sizes(maxWidth: 4096, jpegProgressive: true) {
          sizes
          src
          srcSet
          presentationWidth
          presentationHeight
        }
        original {
          height
          width
        }
      }
    }
  }
}
`
